.dark path[fill="#1d1d1d"] {
    fill:rgb(39, 46, 54);
}

.dark g[stroke="#3e3e3e"] {
    stroke: rgb(67, 80, 94);
}

.dark ellipse[fill="#272727"] {
    fill:rgb(0, 0, 0, .1);
}

/* link */
.dark .app-table-link { 
    color: rgba(255, 255, 255, 0.85) !important;
}

.dark .app-table-link:hover,
.dark .app-table-link:focus {
    color: rgba(255, 255, 255, 1);
}

/* 修改Chrome浏览器的滚动条样式 */
.dark ::-webkit-scrollbar {
    width: 7px; /* 设置滚动条宽度 */
    height: 7px;
    border-radius: 6px;
}

/* 滚动条滑块样式 */
.dark ::-webkit-scrollbar-thumb {
    background-color: #303942; /* 设置滑块颜色 */
    border-radius: 6px;
}

/* 滚动条背景样式 */
.dark ::-webkit-scrollbar-track {
    background-color: #24292d; /* 设置背景颜色 */
    border-radius: 6px;
}

/* 修改Edge浏览器的滚动条样式 */
.dark ::-ms-scrollbar {
    width: 6px; /* 设置滚动条宽度 */
    border-radius: 6px;
}

/* 滚动条滑块样式 */
.dark ::-ms-thumb {
    background-color: #303942; /* 设置滑块颜色 */
    border-radius: 6px;
}

/* 滚动条背景样式 */
.dark ::-ms-track {
    background-color: #24292d; /* 设置背景颜色 */
    border-radius: 6px;
}

.dark .filter-separater {
    color: rgba(255, 255, 255, 0.4);
}

.dark .ant-table-wrapper .ant-table-row-expand-icon:focus,
.dark .ant-table-wrapper .ant-table-row-expand-icon:hover {
    color: rgba(255, 255, 255, 0.85);
}

.dark .ant-layout .ant-layout-sider-trigger {
    color: rgba(255, 255, 255, 0.4);
}

.dark .ant-table-row-highlight {
    background-color:rgba(255, 249, 223, 0.1) !important;
}
.dark .ant-table-row-highlight > td.ant-table-cell-row-hover {
    background: rgba(255, 255, 255, 0.03)  !important;
}